p {
  margin: 0 !important;
}

.header {
  height: 200px;
  width: 100vw;
  background-color: #e9eef6;
  padding-top: 50px;
}

.card-wrapper {
  width: 570px;
  min-height: 60px;
  /* box-shadow: 0px 3px 50px #a5a5a5; */
  background: #fff;
}

.card-top {
  width: 100%;
  height: 2%;
}

.task-holder {
  width: 100%;
  height: 98%;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  position: relative;
}

.card-header {
  margin-bottom: 10px;
  max-width: 80px;
  height: 30px;
  padding: 1px 1px !important;
  text-align: center;
}

.task-container {
  height: calc(100vh - 200px);
  width: 100vw;
  padding: 40px 100px 0 100px;
  background-color: #edf4ff;
}
.task-inner {
  height: 100%;
  width: 600px;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  gap: 20px;
  flex-wrap: nowrap;
  margin: auto;
  background-color: #edf4ff !important;
  overflow-y: scroll;

}

.task-inner::-webkit-scrollbar-track {
  background: fff;
}
.task-inner::-webkit-scrollbar-thumb {
  background: #cdcdcd;
}
.task-inner::-webkit-scrollbar-thumb:hover {
    background: #bfbfbf;
  }
.task-inner::-webkit-scrollbar {
  width: 5px;
}

.task-inner2 {
  height: 600px;
  width: 600px;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  gap: 10px;
  flex-wrap: wrap;
  margin: auto;
  background-color: #ddd;
  padding: 20px;
}

.cardd {
  background: #fff;
  width: 90%;
  padding: 10px;
}

@media screen and (max-width: 650px) {
  .task-inner {
    height: 100%;
    width: 100%;
  }
  .card-wrapper {
    width: 100%;
    height: 80px;
  }
  .task-container {
    padding: 40px 10px;
  }
}
